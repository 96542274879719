<template>
    <a-select v-model="instance" :options="instances" />

</template>

<script>
export default {
    props:{
        value:{
            type:String,
            required:true,
            default: ''
        },
    },
    data(){
        return {
            instance:'',
			instances:[]
        }
    },
    computed:{

    },
    watch:{
        instance:{
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        }
    },
	methods:{

	},
    async created(){
		this.$api.get(`/instances/:instance`).then( ({data}) => {
			let {children = [],...instance} = data
			let options = [instance,...children]
			options = options.map( ({id,name,api}) => ({
				label:name,
				value:api.key || id
			}))
			this.instances = options

			if (this.value){
				this.instance = this.value
			}
		}).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;

    }
</style>
